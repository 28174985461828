<div>
	<slot />
</div>

<style>
	div {
		margin-bottom: var(--spacing-lg);
		color: var(--block-info-text-color);
		font-weight: var(--block-info-text-weight);
		font-size: var(--block-info-text-size);
		line-height: var(--line-sm);
	}
</style>
